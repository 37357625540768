<template>
  <div
    class=" pl-0 pr-4 py-1 timetable-item"
    :class="
      isChecklistItem
        ? 'bg-gray-800 my-3 mx-3 border border-gray-700 rounded'
        : 'rounded-xl shadow-lg bg-level-1 my-1'
    "
  >
    <div class="flex justify-start items-center pt-1">
      <div class="text-xs text-sub text-opacity-75 rounded-lg pl-4 pr-3 draggable-handle">
        <font-awesome-icon :icon="['fas', 'grip-vertical']" />
      </div>
      <div class="flex-grow">
        <div class="flex justify-between items-center">
          <p
            class="font-bold"
            :class="
              item.is_end && !isChecklistItem
                ? 'text-sub text-opacity-50 line-through'
                : 'text-main'
            "
          >
            {{ name }}
          </p>
          <p
            v-if="item.inProgress && !isChecklistItem"
            class="text-xs text-yellow-400 font-number mr-1"
          >
            進行中
          </p>
          <p v-else-if="!item.is_end && !isChecklistItem" class="text-xs text-sub font-number mr-1">
            {{ estTime }}
          </p>
        </div>
      </div>
      <button
        v-if="!item.is_end && !isChecklistItem"
        :disabled="!item.inProgress"
        class="bg-level-2 text-xs text-green-400 rounded-lg px-2 py-1 focus:outline-none mr-1 font-bold"
        :class="{ 'text-opacity-25': !item.inProgress }"
        @click="$emit('click-mark-as-end')"
      >
        完了
      </button>
      <button
        v-else-if="!isChecklistItem"
        :disabled="!item.canUndo"
        class="bg-level-2 text-xs text-blue-400 rounded-lg px-2 py-1 focus:outline-none mr-1 font-bold"
        :class="{ 'text-opacity-25': !item.canUndo }"
        @click="$emit('click-mark-as-not-end')"
      >
        <font-awesome-icon :icon="['fas', 'undo']" />
      </button>
      <button
        v-if="!isChecklistItem"
        class="bg-level-2 text-xs text-sub rounded-lg px-2 py-1 focus:outline-none mr-1"
        @click="$emit('click-edit')"
      >
        <font-awesome-icon :icon="['fas', 'pen']" />
      </button>
      <button
        class="bg-level-2 text-xs text-red-400 rounded-lg px-2 py-1 focus:outline-none"
        @click="$emit('click-delete')"
      >
        <font-awesome-icon :icon="['fas', 'times']" />
      </button>
    </div>
    <div class="pl-5 pr-2 pt-0.5 text-sub text-opacity-75 text-xxs pb-1 break-all">
      <!-- eslint-disable vue/no-v-html -->
      <template v-if="!isChecklistItem"
        >{{ item.time_minute }}分<template v-if="delayTime !== null">
          →
          <span v-if="delayTime <= 0" class="text-blue-400"
            >{{ delayTime + item.time_minute }}分 [{{
              delayTime === 0 ? '定刻通り' : `${-delayTime}分巻き`
            }}]</span
          >
          <span v-else class="text-red-400"
            >{{ delayTime + item.time_minute }}分 [{{ delayTime }}分押し]</span
          ></template
        ><br /></template
      ><span class="whitespace-pre-line" v-html="linkifiedComment" />
      <!-- eslint-enable -->
    </div>
    <div v-if="item.todo_collection" class="pl-4 py-2">
      <div
        role="button"
        class="w-full bg-primary-900 py-2 px-5 rounded-2xl text-white font-semibold shadow-2xl focus:outline-none relative"
        @click="$emit('click-checklist')"
      >
        <div class="mb-6 truncate text-sm">{{ item.todo_collection.name }}</div>
        <div v-if="checklistCompleted" class="flex-grow absolute bottom-2 right-5">
          <ui-label class="px-3 text-xs" color="success">ALL DONE</ui-label>
        </div>
        <ui-progress
          v-else
          class="flex-grow absolute bottom-2 left-5 right-5"
          :value="completedChecklistCount"
          :max="todoCollectionTotalLength"
        />
      </div>
      <div
        v-if="item.is_end && !checklistCompleted"
        class="text-red-500 text-sm mt-1 text-center font-semibold"
      >
        <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="mr-1" />
        チェックリスト未完了です！
      </div>
    </div>
    <div
      v-if="isChecklistItem && !item.todo_collection && item.is_end"
      class="text-red-500 text-xs my-1 text-center"
    >
      チェックリストが完了次第、<font-awesome-icon :icon="['fas', 'times']" />ボタンを押して<br />
      この項目を削除してください！
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns'
import sanitizeHtml from 'sanitize-html'
import UiProgress from '@/components/@ui/UiProgress'
import UiLabel from '@/components/@ui/UiLabel'

export default {
  name: 'TimetableItem',
  components: { UiLabel, UiProgress },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isChecklistItem() {
      return this.item.time_minute === 0
    },
    linkifiedComment() {
      const escaped = sanitizeHtml(this.item.comment, {
        allowedTags: [],
        allowedAttributes: {},
        disallowedTagsMode: 'escape',
      })
      const urlRegExp = /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*))/gi
      return escaped
        .replace(/(.+)/g, '<p>$1</p>')
        .replace(
          urlRegExp,
          '<span class="inline-block truncate w-full"><a class="text-purple-400 underline" href="$1" target="_blank">$1</a></span>',
        )
    },
    name() {
      return `${this.item.label ? `${this.item.label.name} ` : ''}${this.item.scene}`
    },
    estTime() {
      if (!this.item.est) {
        return '--:--'
      }
      return format(this.item.est, 'HH:mm')
    },
    delayTime() {
      return !this.item.end_ts || !this.item.est
        ? null
        : Math.round((Date.parse(this.item.end_ts) - this.item.est.getTime()) / 60000) -
            this.item.time_minute
    },
    todoCollectionTotalLength() {
      return this.item.todo_collection
        ? this.item.todo_collection.todo_lists.reduce((p, c) => p + c.todo_items.length, 0)
        : 0
    },
    completedChecklistCount() {
      return [
        ...new Set(this.item.schedule_timetable_completed_todo_items.map(v => v.todo_item_id)),
      ].length
    },
    checklistCompleted() {
      return (
        this.item.schedule_timetable_completed_todo_items.length === this.todoCollectionTotalLength
      )
    },
  },
}
</script>

<style lang="sass" scoped>
.timetable-item, .timetable-item *
  user-select: none
  -webkit-user-select: none
</style>
