<template>
  <div>
    <ui-transition>
      <article v-if="!isLoading">
        <div v-if="schedule" class="rounded-xl bg-level-1 py-4 px-5 bg-opacity-50 mb-3">
          <div class="flex justify-center items-center col-gap-2">
            <img
              v-if="schedule.thumbnail"
              v-lazy="`/uploads/schedules/${schedule.thumbnail}`"
              class="w-16 h-16 rounded-lg shadow-xl object-cover flex-shrink-0"
            />
            <img
              v-else
              :key="schedule.actresses[0].id"
              v-lazy="`/uploads/actresses/${schedule.actresses[0].thumbnail}`"
              class="w-16 h-16 rounded-lg shadow-xl object-cover flex-shrink-0"
            />
          </div>
          <h3 class="text-main text-lg text-center my-2">
            {{ schedule.title }}
          </h3>
          <ui-button v-if="!schedule.start_ts" size="md" color="success" @click="startSchedule">
            撮影開始
          </ui-button>
          <div v-else class="px-4 py-3 w-full text-center rounded-xl bg-level-1 text-sub">
            <p class="text-lg">{{ scheduleStartTime }} 撮影開始</p>
          </div>
          <div v-if="schedule.start_ts" class="mt-2 text-main text-center">
            <span v-if="!isEnd" class="text-xs">現在</span>
            <span v-if="delayTime >= 5" class="text-red-500">
              <span class="ml-1 font-number font-bold text-lg">{{ Math.abs(delayTime) }}</span>
              <span class="mr-1 font-bold text-xs">分遅れ</span>
            </span>
            <span v-else-if="delayTime <= -5" class="text-blue-500">
              <span class="ml-1 font-number font-bold text-lg">{{ Math.abs(delayTime) }}</span>
              <span class="mr-1 font-bold text-xs">分巻き</span>
            </span>
            <span v-else class="text-blue-500">
              <span class="mx-1 font-bold">予定通り</span>
            </span>
            <span v-if="!isEnd" class="text-xs">で進行中です</span>
            <span v-else class="text-xs">で終了しました</span>
          </div>
        </div>
        <div
          v-if="timetables.length === 0"
          class="text-center text-sub text-opacity-75 py-6 bg-level2 text-sm"
        >
          タイムテーブルが登録されていません
        </div>
        <draggable
          v-else
          v-model="timetables"
          :move="checkMove"
          filter=".draggable-disabled"
          handle=".draggable-handle"
          :options="{ delay: 600 }"
          :animation="200"
          ghost-class="draggable-ghost"
          @end="onDragEnd"
        >
          <timetable-item
            v-for="timetable in computedTimeTables"
            :key="timetable.id"
            :data-id="timetable.id"
            :item="timetable"
            :class="{ 'draggable-disabled': timetable.is_end }"
            @click-mark-as-end="markAsEnd(timetable.id)"
            @click-mark-as-not-end="markAsNotEnd(timetable.id)"
            @click-edit="openEditDialog(timetable)"
            @click-delete="
              openDeleteConfirmDialog({
                id: timetable.id,
                name: `${timetable.label ? timetable.label.name : ''}${timetable.scene}`,
              })
            "
            @click-checklist="onClickChecklist(timetable)"
          />
        </draggable>
        <div class="text-center text-sub text-xs mt-4">
          <p>終了<span v-if="!isEnd">予定</span>時刻</p>
          <p class="text-lg font-number">{{ lastTimeEstimate }}</p>
        </div>
        <div class="flex justify-center flex-col items-center pt-8 pb-4">
          <button
            type="button"
            class="px-3 py-1 text-orange-400 text-sm border border-orange-400 border-opacity-25 focus:outline-none"
            @click="rescueConfirmDialog = true"
          >
            順序情報をリセットする
          </button>
          <button
            v-if="schedule.start_ts"
            type="button"
            class="mt-8 px-3 py-1 text-red-500 text-xs  focus:outline-none"
            @click="resetConfirmDialog = true"
          >
            進捗情報を削除する
          </button>
        </div>
      </article>
    </ui-transition>
    <timetable-checklist-dialog
      v-model="checklistDialog"
      :timetable="checklistTarget"
      @toggle-checkbox="onTodoItemCheckboxToggled"
      @all-checkbox-checked="onAllTodoItemCheckboxesChecked"
    />
    <timetable-edit-dialog
      v-model="editDialog"
      :is-new="!editTarget.id"
      :default-form="editTarget.defaultForm"
      @click-save="saveThenCloseEditDialog"
      @click-cancel="closeEditDialog"
    />
    <ui-dialog
      v-model="resetConfirmDialog"
      title="このタイムテーブルの進捗情報を削除します。この操作は復元できません。本当によろしいですか？"
      type="error"
    >
      <ui-button color="error" class="mb-2" @click="reset">
        進捗情報を削除
      </ui-button>
      <ui-button @click="resetConfirmDialog = false">
        キャンセル
      </ui-button>
    </ui-dialog>
    <ui-dialog
      v-model="deleteConfirmDialog"
      title="確認"
      type="error"
      :description="`${deleteTarget.name}を削除します。よろしいですか？`"
    >
      <ui-button color="error" class="mb-2" @click="deleteSceneThenCloseConfirmDialog">
        削除
      </ui-button>
      <ui-button @click="closeDeleteConfirmDialog">
        キャンセル
      </ui-button>
    </ui-dialog>
    <ui-dialog
      v-model="rescueConfirmDialog"
      title="順序情報をリセットします。よろしいですか？"
      type="warning"
      :description="`正常に並び替えができないなどの\n不具合が起きた場合のみに使用してください`"
    >
      <ui-button color="warning" class="mb-2" @click="rescue">
        リセット
      </ui-button>
      <ui-button @click="rescueConfirmDialog = false">
        キャンセル
      </ui-button>
    </ui-dialog>
  </div>
</template>

<script>
import { format } from 'date-fns'
import draggable from 'vuedraggable'
import TimetableService from '@/services/TimetableService'
import ScheduleService from '@/services/ScheduleService'
import TimetableItem from '@/components/timetable/TimetableItem.vue'
import TimetableEditDialog from '@/components/timetable/TimetableEditDialog.vue'
import TimetableChecklistDialog from '@/components/timetable/TimetableChecklistDialog'
import UiButton from '@/components/@ui/UiButton.vue'

export default {
  name: 'Timetable',
  components: {
    UiButton,
    TimetableChecklistDialog,
    draggable,
    TimetableEditDialog,
    TimetableItem,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isLoading: true,
    schedule: null,
    timetables: [],
    showEndScene: false,
    ws: null,
    wsHealthCheckTimer: null,
    wsReconnectTimer: null,
    editTarget: {
      id: null,
      defaultForm: {
        label_id: null,
        scene: '',
        time_minute: '',
        comment: '',
      },
    },
    editDialog: false,
    deleteConfirmDialog: false,
    rescueConfirmDialog: false,
    resetConfirmDialog: false,
    deleteTarget: {
      id: 0,
      name: '',
    },
    checklistDialog: false,
    checklistTarget: null,
  }),
  computed: {
    isEnd() {
      return this.timetables.every(v => v.is_end)
    },
    lastTimeEstimate() {
      if (!this.schedule || !this.schedule.start_ts) {
        return '--:--'
      }
      let baseTs
      const lastEndIndex = this.timetables.findIndex(v => !v.is_end)
      if (lastEndIndex === 0) {
        baseTs = new Date(this.schedule.start_ts).getTime()
      } else if (lastEndIndex === -1) {
        baseTs = new Date(this.timetables[this.timetables.length - 1].end_ts).getTime()
      } else {
        baseTs = new Date(this.timetables[lastEndIndex - 1].end_ts).getTime()
      }
      const estimateTime =
        lastEndIndex === -1
          ? 0
          : this.timetables.slice(lastEndIndex).reduce((p, c) => p + c.time_minute * 60000, 0)
      return format(new Date(baseTs + estimateTime), 'HH:mm')
    },
    delayTime() {
      if (!this.schedule || !this.schedule.start_ts) {
        return 0
      }
      let lastEndIndex = this.timetables.findIndex(v => !v.is_end)
      if (lastEndIndex === 0) {
        return 0
      }
      if (lastEndIndex === -1) {
        lastEndIndex = this.timetables.length
      }
      const startTs = new Date(this.schedule.start_ts)
      const endTs = new Date(this.timetables[lastEndIndex - 1].end_ts)
      const actualTime = endTs.getTime() - startTs.getTime()
      const expectedTime = this.timetables
        .slice(0, lastEndIndex)
        .reduce((p, c) => p + c.time_minute * 60000, 0)

      return Math.round((actualTime - expectedTime) / 60000)
    },
    scheduleStartTime() {
      if (this.schedule && this.schedule.start_ts) {
        const d = new Date(this.schedule.start_ts)
        return format(d, 'HH:mm')
      }
      return ''
    },
    computedTimeTables() {
      const ests = []
      for (let i = 0; i < this.timetables.length; i += 1) {
        if (!this.schedule || !this.schedule.start_ts) {
          ests.push(null)
        } else if (i === 0) {
          ests.push(new Date(this.schedule.start_ts))
        } else if (this.timetables[i - 1].end_ts) {
          ests.push(new Date(this.timetables[i - 1].end_ts))
        } else {
          ests.push(new Date(ests[i - 1].getTime() + this.timetables[i - 1].time_minute * 60000))
        }
      }
      const firstIndex = this.timetables.findIndex(v => !v.is_end)
      let canUndoFirstIndex = firstIndex === -1 ? this.timetables.length - 1 : firstIndex - 1
      for (let i = canUndoFirstIndex; i >= 0; i--) {
        if (this.timetables[canUndoFirstIndex].time_minute === 0) {
          canUndoFirstIndex--
        } else {
          break
        }
      }
      return this.timetables.map((v, i) => ({
        ...v,
        inProgress: this.schedule.start_ts && i === firstIndex,
        canUndo: i === canUndoFirstIndex,
        est: ests[i],
      }))
    },
  },
  async created() {
    this.subscribe()
    this.fetch()
  },
  beforeDestroy() {
    this.stopWsReconnectTimer()
    this.stopWsHealthCheckTimer()
    this.ws.close()
  },
  methods: {
    async fetch() {
      this.isLoading = true
      const [timetables, schedule] = await Promise.all([
        TimetableService.get(this.id),
        ScheduleService.getOne(this.id),
      ])
      this.timetables = timetables
      this.schedule = schedule
      this.isLoading = false
    },
    async startSchedule() {
      await ScheduleService.start(this.$deviceId, this.id)
      await this.silentFetch()
    },
    async silentFetch() {
      this.timetables = await TimetableService.get(this.id)
      this.schedule = await ScheduleService.getOne(this.id)
      if (this.checklistTarget) {
        this.checklistTarget =
          this.timetables.find(v => v.id === this.checklistTarget.id) || this.checklistTarget
      }
    },
    async markAsEnd(id) {
      await TimetableService.markAsEnd(this.$deviceId, id)
      await this.silentFetch()
    },
    async markAsNotEnd(id) {
      await TimetableService.markAsNotEnd(this.$deviceId, id)
      await this.silentFetch()
    },
    canMove(evt) {
      return !evt.draggedContext.element.is_end
    },
    openEditDialog(target) {
      if (target) {
        this.editTarget = {
          id: target.id,
          defaultForm: {
            label_id: target.label ? target.label.id : null,
            scene: target.scene,
            time_minute: target.time_minute,
            comment: target.comment,
          },
        }
      } else {
        this.editTarget = {
          id: null,
          defaultForm: {
            label_id: null,
            scene: '',
            time_minute: '',
            comment: '',
          },
        }
      }
      this.editDialog = true
    },
    async saveThenCloseEditDialog(content) {
      if (this.editTarget.id) {
        await TimetableService.update(this.$deviceId, this.editTarget.id, content)
      } else {
        await TimetableService.create(this.$deviceId, this.id, content)
      }
      this.closeEditDialog()
      await this.silentFetch()
    },
    closeEditDialog() {
      this.editTarget = {
        id: null,
        defaultForm: {
          label_id: null,
          scene: '',
          time_minute: '',
          comment: '',
        },
      }
      this.editDialog = false
    },
    openDeleteConfirmDialog(target) {
      this.deleteConfirmDialog = true
      this.deleteTarget = target
    },
    async deleteSceneThenCloseConfirmDialog() {
      await TimetableService.remove(this.$deviceId, this.deleteTarget.id)
      this.closeDeleteConfirmDialog()
      await this.silentFetch()
    },
    async rescue() {
      this.rescueConfirmDialog = false
      await TimetableService.rescue(this.$deviceId, this.id)
      await this.fetch()
    },
    async reset() {
      this.resetConfirmDialog = false
      await ScheduleService.reset(this.$deviceId, this.id)
      await this.fetch()
    },
    onClickChecklist(target) {
      this.checklistDialog = true
      this.checklistTarget = target
    },
    async onTodoItemCheckboxToggled(evt) {
      await TimetableService.toggleCheckbox(this.$deviceId, evt.timetableId, evt.itemId, evt.value)
    },
    async onAllTodoItemCheckboxesChecked() {
      // await TimetableService.markAsEnd(this.$deviceId, evt.timetableId)
      this.$store.dispatch('notification/set', {
        message: '全てチェックされました！',
        timeout: 1000,
      })
    },
    closeDeleteConfirmDialog() {
      this.deleteConfirmDialog = false
      this.deleteTarget = {
        id: 0,
        name: '',
      }
    },
    checkMove(event) {
      const current = this.timetables[event.draggedContext.futureIndex]
      if (current && current.is_end) {
        return false
      }
      return true
    },
    async onDragEnd(event) {
      if (event.newIndex === event.oldIndex) return
      await TimetableService.sort(
        this.$deviceId,
        this.id,
        this.timetables[event.newIndex].id,
        event.newIndex + 1,
      )
      await this.silentFetch()
    },
    subscribe() {
      return new Promise(resolve => {
        this.ws = new WebSocket('wss://fmapp.net/ws/connection')
        this.ws.onopen = () => resolve()
        this.ws.onmessage = event => {
          if (event.data === 'pong') {
            this.stopWsReconnectTimer()
            return
          }
          const data = JSON.parse(event.data)
          if (data.deviceId === this.$deviceId) return
          if (data.app !== 'timetable') return
          if (Number(data.scheduleId) !== Number(this.id)) return
          this.silentFetch()
        }

        this.wsHealthCheckTimer = setInterval(() => {
          this.ws.send('ping')
          this.startWsReconnectTimer()
        }, 10000)
      })
    },
    startWsReconnectTimer() {
      this.wsReconnectTimer = setTimeout(() => {
        this.stopWsHealthCheckTimer()
        this.ws.close()
        this.subscribe()
        this.silentFetch()
      }, 3000)
    },
    stopWsReconnectTimer() {
      clearTimeout(this.wsReconnectTimer)
    },
    stopWsHealthCheckTimer() {
      clearInterval(this.wsHealthCheckTimer)
    },
  },
}
</script>

<style lang="scss">
.sortable-chosen {
  filter: brightness(120%);
}
</style>
