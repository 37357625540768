<template>
  <ui-fullscreen-modal v-model="computedValue">
    <template v-if="timetable && timetable.todo_collection">
      <div class="mt-3 px-2 py-3">
        <div class="flex items-end justify-between">
          <p class="text-white text-lg font-extrabold flex-shrink-0">
            チェックリスト
          </p>
          <div class="flex items-center">
            <div class="text-sub text-xs mr-2">
              未完了のタスクのみ表示
            </div>
            <ui-switch v-model="showOnlyIncompletedTasks" />
          </div>
        </div>
        <ui-progress class="mt-1" :value="checked" :max="all" />
      </div>
      <section v-for="list in timetable.todo_collection.todo_lists" :key="list.id" class="my-2">
        <transition
          enter-active-class="ease-out transform duration-100"
          enter-class="opacity-0 scale-110"
          enter-to-class="opacity-100 scale-100"
          leave-active-class="ease-in transform duration-100 delay-200"
          leave-class="opacity-100 scale-100"
          leave-to-class="opacity-0 scale-90"
        >
          <div
            v-if="
              list.todo_items.length &&
                (!showOnlyIncompletedTasks ||
                  list.todo_items.some(item => !innerChecked.includes(item.id)))
            "
            class="mb-6 px-1"
          >
            <h2
              class="text-sm font-bold text-main mb-3 border-b border-gray-600 py-2 border-opacity-50"
            >
              {{ list.name }}
            </h2>
            <div v-for="item in list.todo_items" :key="item.id">
              <todo-item
                :item="item"
                :checked-status="innerChecked.includes(item.id)"
                :hide-if-checked="showOnlyIncompletedTasks"
                @toggle-checkbox="toggleCheckbox(item.id, $event)"
              />
            </div>
          </div>
        </transition>
      </section>
    </template>
  </ui-fullscreen-modal>
</template>

<script>
import UiFullscreenModal from '@/components/@ui/UiFullscreenModal'
import TodoItem from '@/components/todo/TodoItem'
export default {
  name: 'TimetableChecklistDialog',
  components: { TodoItem, UiFullscreenModal },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    timetable: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showOnlyIncompletedTasks: false,
      innerChecked: [],
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    all() {
      return this.timetable.todo_collection.todo_lists.reduce((p, c) => p + c.todo_items.length, 0)
    },
    checked() {
      return this.innerChecked.length
    },
  },
  watch: {
    'timetable.schedule_timetable_completed_todo_items': {
      handler() {
        this.innerChecked = [
          ...new Set(
            this.timetable.schedule_timetable_completed_todo_items.map(v => v.todo_item_id),
          ),
        ]
      },
      deep: true,
    },
  },
  mounted() {
    this.$moduloBox.getGalleries()
  },
  updated() {
    this.$nextTick(() => {
      this.$moduloBox.getGalleries()
    })
  },
  methods: {
    toggleCheckbox(itemId, value) {
      if (value) {
        if (!this.innerChecked.includes(itemId)) {
          this.innerChecked.push(itemId)
        }
      } else {
        this.innerChecked = this.innerChecked.filter(v => v !== itemId)
      }
      if (this.all === this.checked) {
        this.$emit('all-checkbox-checked', { timetableId: this.timetable.id })
      }
      this.$emit('toggle-checkbox', {
        timetableId: this.timetable.id,
        itemId,
        value,
      })
    },
  },
}
</script>
