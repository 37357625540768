<template>
  <ui-dialog v-model="computedValue" class="z-30" :title="isNew ? 'シーンの登録' : 'シーンの更新'">
    <template #content>
      <div class="text-left">
        <ui-select v-model="editedItem.label_id" label="品番" :options="labels" />
        <ui-input
          v-model="editedItem.scene"
          label="シーン名"
          placeholder="1,2,sp,休憩など"
          class="mt-4"
          :required="true"
        />
        <ui-input
          v-model="editedItem.time_minute"
          type="number"
          number-only
          label="所要時間"
          class="mt-4"
          :required="true"
        />
        <ui-textarea v-model="editedItem.comment" label="コメント" class="mt-4" />
      </div>
    </template>
    <ui-button
      color="primary"
      :disabled="disabled"
      class="mb-2"
      @click="$emit('click-save', { ...editedItem })"
    >
      {{ isNew ? '登録' : '変更' }}
    </ui-button>
    <ui-button class="mb-2" @click="$emit('click-cancel')">
      キャンセル
    </ui-button>
  </ui-dialog>
</template>

<script>
import LabelService from '../../services/LabelService'

export default {
  name: 'TimetableEditDialog',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    isNew: {
      type: Boolean,
      default: true,
    },
    defaultForm: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      labels: [],
      editedItem: {
        label_id: null,
        scene: '',
        time_minute: '',
        comment: '',
      },
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    disabled() {
      return !this.editedItem.scene || this.editedItem.time_minute === ''
    },
  },
  watch: {
    defaultForm: {
      deep: true,
      handler(value) {
        this.editedItem = { ...value }
      },
    },
  },
  async created() {
    const labels = await LabelService.get()
    this.labels = [{ id: null, name: '未設定' }, ...labels].map(item => ({
      value: item.id,
      text: item.name,
    }))
  },
}
</script>
