<template>
  <div>
    <figure
      v-if="schedule.id"
      class="schedule__header-image -mx-6 -mb-3"
    >
      <img
        v-if="schedule.thumbnail"
        class="h-72 w-full object-cover"
        :src="`/uploads/schedules/${schedule.thumbnail}`"
      >
      <img
        v-else-if="schedule.actresses[0].thumbnail"
        class="h-72 w-full object-cover"
        :src="`/uploads/actresses/${schedule.actresses[0].thumbnail}`"
      >
    </figure>
    <ui-transition>
      <div v-if="schedule.id">
        <div class="text-center">
          <ui-label
            class="inline-block py-1 px-3 text-xs mb-2"
          >
            撮影詳細
          </ui-label>
        </div>
        <p class="text-sm text-sub">
          {{ schedule.shooting_date | date }}
        </p>
        <p class="text-2xl font-extrabold text-main">
          {{ schedule.title }}
        </p>
      </div>
    </ui-transition>
  </div>
</template>

<script>
import { parse, format } from 'date-fns';
import ScheduleService from '../services/ScheduleService';

export default {
  name: 'ScheduleDetail',
  filters: {
    date(date) {
      return format(parse(date, 'yyyy-MM-dd', new Date()), 'yyyy年M月d日');
    },
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isLoading: true,
    schedule: {},
  }),
  watch: {
    id() {
      this.fetch();
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      this.schedule = {};
      this.isLoading = true;
      this.schedule = await ScheduleService.getOne(this.id, true);
      this.isLoading = false;
    },
  },

};
</script>

<style lang="sass">
.schedule__header-image
  margin-top: calc(-60px - constant(safe-area-inset-top))
  margin-top: calc(-60px - env(safe-area-inset-top))
</style>
